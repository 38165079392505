// src/components/PublicRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isAuthenticated, userDB } = useAppSelector((state: RootState) => state.auth);

  if (isAuthenticated && userDB) {
    return <Navigate to="/chat" />;
  }

  return children;
};

export default PublicRoute;
