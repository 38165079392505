import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc } from "firebase/firestore";
import { firestore } from "../configs/firebase-config";
import { LawCompanyModel } from "../models/LawCompanyModel";

export class LawCompaniesService {
  private firestore = firestore;
  private lawCompaniesRef = collection(this.firestore, "law_firms");
  async getLawCompanies(): Promise<LawCompanyModel[]> {
    const lawCompaniesSnapshot = query(this.lawCompaniesRef, orderBy("added_at", "asc"));
    const querySnapshot = await getDocs(lawCompaniesSnapshot);
    return querySnapshot.docs.map((doc) => LawCompanyModel.fromFirestore(doc));
  }

  async addCompany(company: LawCompanyModel): Promise<void> {
    await addDoc(this.lawCompaniesRef, company.toFirestore());
  }

  async deleteCompany(company: LawCompanyModel): Promise<void> {
    const docRef = doc(this.lawCompaniesRef, company.id);
    await deleteDoc(docRef);
  }

  async updateCompany(company: LawCompanyModel): Promise<void> {
    const docRef = doc(this.lawCompaniesRef, company.id);
    await updateDoc(docRef, company.toFirestore());
  }
}
