import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  fetchLawCompanies,
  addLawCompany,
  updateLawCompany,
  deleteLawCompany,
} from "../../../app/lawCompany/lawCompanySlice";
import { LawCompanyModel } from "../../../models/LawCompanyModel";
import Modal from "../../layout/Modal/Modal";
import PageLoader from "../../layout/PageLoader/PageLoader";
import "./AdminCompaniesPage.scss";

const AdminCompaniesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { lawCompanies, isLoading, error } = useAppSelector((state: RootState) => state.lawCompanies);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentCompany, setCurrentCompany] = useState<LawCompanyModel | null>(null);
  const [companyToDelete, setCompanyToDelete] = useState<LawCompanyModel | null>(null);

  useEffect(() => {
    dispatch(fetchLawCompanies());
  }, [dispatch]);

  const handleAddCompany = () => {
    setEditMode(false);
    setCurrentCompany(new LawCompanyModel("", "", "", "", "", ""));
    setIsModalVisible(true);
  };

  const handleEditCompany = (company: LawCompanyModel) => {
    setEditMode(true);
    setCurrentCompany(company);
    setIsModalVisible(true);
  };

  const handleDeleteCompany = (company: LawCompanyModel) => {
    setCompanyToDelete(company);
    setIsDeleteModalVisible(true);
  };

  const confirmDeleteCompany = async () => {
    if (companyToDelete) {
      setIsDeleteModalVisible(false);

      await dispatch(deleteLawCompany(companyToDelete));
    }
  };

  const handleModalConfirm = async () => {
    if (currentCompany) {
      if (editMode) {
        await dispatch(updateLawCompany(currentCompany));
      } else {
        await dispatch(addLawCompany(currentCompany));
      }
    }
    setIsModalVisible(false);
  };

  return (
    <div className="admin-companies-page">
      <h1>Управление юридическими компаниями</h1>
      <button onClick={handleAddCompany}>Добавить компанию</button>
      {error && <p>Error: {error}</p>}
      <ul className="company-list">
        {isLoading && <PageLoader />}
        {lawCompanies.map((company) => (
          <li key={company.id} className="company-item">
            <div className="company-details">
              <h3>{company.name}</h3>
              <p>{company.description}</p>
              <p>Адрес: {company.address}</p>
              <p>
                <a href={company.link2gis} target="_blank" rel="noopener noreferrer">
                  Ссылка на 2GIS
                </a>
              </p>
              <p>Телефон: {company.phoneNumber}</p>
            </div>
            <div className="company-actions">
              <button onClick={() => handleEditCompany(company)}>Редактировать</button>
              <button onClick={() => handleDeleteCompany(company)}>Удалить</button>
            </div>
          </li>
        ))}
      </ul>
      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onConfirm={handleModalConfirm}
        title={editMode ? "Редактировать компанию" : "Добавить компанию"}
        message="Заполните информацию о компании"
      >
        <form className="company-form">
          <label>
            Название:
            <input
              type="text"
              value={currentCompany?.name || ""}
              onChange={(e) =>
                setCurrentCompany(
                  new LawCompanyModel(
                    currentCompany?.id || "",
                    e.target.value,
                    currentCompany?.description || "",
                    currentCompany?.address || "",
                    currentCompany?.link2gis || "",
                    currentCompany?.phoneNumber || ""
                  )
                )
              }
            />
          </label>
          <label>
            Описание:
            <textarea
              value={currentCompany?.description || ""}
              onChange={(e) =>
                setCurrentCompany(
                  new LawCompanyModel(
                    currentCompany?.id || "",
                    currentCompany?.name || "",
                    e.target.value,
                    currentCompany?.address || "",
                    currentCompany?.link2gis || "",
                    currentCompany?.phoneNumber || ""
                  )
                )
              }
            />
          </label>
          <label>
            Адрес:
            <input
              type="text"
              value={currentCompany?.address || ""}
              onChange={(e) =>
                setCurrentCompany(
                  new LawCompanyModel(
                    currentCompany?.id || "",
                    currentCompany?.name || "",
                    currentCompany?.description || "",
                    e.target.value,
                    currentCompany?.link2gis || "",
                    currentCompany?.phoneNumber || ""
                  )
                )
              }
            />
          </label>
          <label>
            Ссылка на 2GIS:
            <input
              type="text"
              value={currentCompany?.link2gis || ""}
              onChange={(e) =>
                setCurrentCompany(
                  new LawCompanyModel(
                    currentCompany?.id || "",
                    currentCompany?.name || "",
                    currentCompany?.description || "",
                    currentCompany?.address || "",
                    e.target.value,
                    currentCompany?.phoneNumber || ""
                  )
                )
              }
            />
          </label>
          <label>
            Телефон:
            <input
              type="text"
              value={currentCompany?.phoneNumber || ""}
              onChange={(e) =>
                setCurrentCompany(
                  new LawCompanyModel(
                    currentCompany?.id || "",
                    currentCompany?.name || "",
                    currentCompany?.description || "",
                    currentCompany?.address || "",
                    currentCompany?.link2gis || "",
                    e.target.value
                  )
                )
              }
            />
          </label>
        </form>
      </Modal>
      <Modal
        isVisible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onConfirm={confirmDeleteCompany}
        title="Подтверждение удаления"
        message="Вы уверены, что хотите удалить эту компанию? Это действие необратимо."
      />
    </div>
  );
};

export default AdminCompaniesPage;
