import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { useTranslation } from "react-i18next";

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <nav className="sidebar">
      <ul>
        <li>
          <NavLink to="/chat">{t("sidebar.askQuestion")}</NavLink>
        </li>
        <li>
          <NavLink to="/companies">{t("sidebar.contactLawyer")}</NavLink>
        </li>
        <li>
          <NavLink to="/documents">{t("sidebar.normativeLegalActs")}</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
