import { getStorage, ref, listAll, getDownloadURL, deleteObject, uploadBytesResumable } from "firebase/storage";
import { DocumentModel } from "../models/DocumentModel";

const storage = getStorage();

export interface DocumentsSection {
  title: string;
  documents: DocumentModel[];
}

export class DocumentsService {
  static fetchDocuments = async (country: string): Promise<DocumentsSection[]> => {
    const documentSections: DocumentsSection[] = [];
    const countryRef = ref(storage, country);

    const folderList = await listAll(countryRef);

    for (const folderRef of folderList.prefixes) {
      const folderName = folderRef.name;

      const documentsSnapshot = await listAll(folderRef);
      const documents: DocumentModel[] = [];

      for (const fileRef of documentsSnapshot.items) {
        const url = await getDownloadURL(fileRef);
        documents.push(new DocumentModel(fileRef.name, fileRef.name.replace(".pdf", ""), url, fileRef.fullPath));
      }

      documentSections.push({
        title: folderName,
        documents,
      });
    }

    return documentSections;
  };

  static createDocument = async (
    country: string,
    folder: string,
    file: File,
    onProgress: (progress: number) => void
  ): Promise<void> => {
    const documentRef = ref(storage, `${country}/${folder}/${file.name}`);
    const uploadTask = uploadBytesResumable(documentRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
          reject(error);
        },
        () => {
          resolve();
        }
      );
    });
  };

  static deleteDocument = async (country: string, document: DocumentModel): Promise<void> => {
    const documentRef = ref(storage, document.lastModified);
    try {
      await deleteObject(documentRef);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };
}
