import {
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
  signOut,
  onAuthStateChanged,
  ConfirmationResult,
  RecaptchaVerifier,
} from "firebase/auth";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { firestore, auth } from "../configs/firebase-config";
import { UserModel } from "../models/UserModel";

const usersRef = collection(firestore, "users");

function userRef(phoneNumber: string) {
  return doc(usersRef, phoneNumber);
}

declare global {
  interface Window {
    _recaptchaVerifier?: RecaptchaVerifier;
    _confirmationResult?: ConfirmationResult;
    _captchaWidgetId?: number;
    grecaptcha?: {
      reset: (widgetId: number) => any;
    };
  }
}

const sendSmsCode = async (phoneNumber: string) => {
  const appVerifier = window._recaptchaVerifier;

  if (!appVerifier) {
    throw new Error("Cannot use invisible captcha. Please reload the page.");
  }

  try {
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    window._confirmationResult = confirmationResult;
    return confirmationResult;
  } catch (confirmationErr) {
    let err = confirmationErr;

    if (window._captchaWidgetId) {
      window.grecaptcha?.reset(window._captchaWidgetId);
    } else {
      try {
        const widgetId = await window._recaptchaVerifier?.render();

        if (widgetId) {
          window._captchaWidgetId = widgetId;
          window.grecaptcha?.reset(widgetId);
        }
      } catch (captchaErr) {
        err = captchaErr;
      }
    }
    throw err;
  }
};

const verifyOtpCode = async (verificationId: string, code: string) => {
  const credential = PhoneAuthProvider.credential(verificationId, code);
  const userCredential = await signInWithCredential(auth, credential);
  const user = userCredential.user;
  const uid = user.uid;
  const phoneNumber = user.phoneNumber!;
  const userDoc = await getDoc(userRef(phoneNumber));
  let userDB: UserModel | null = null;

  if (userDoc.exists()) {
    userDB = UserModel.fromFirestore(userDoc);
  }
  return {
    uid,
    phoneNumber,
    userDB,
  };
};

const createUserInFirestore = async (firstName: string, lastName: string, phoneNumber: string) => {
  const userDB = new UserModel(
    firstName,
    lastName,
    phoneNumber,
    new Date(),
    new Date(),
    "",
    0,
    new Date(),
    "standard_user"
  );
  await setDoc(doc(firestore, "users", phoneNumber), userDB.toFirestore());
  return userDB;
};

const checkUserExistsInFirestore = async (phoneNumber: string) => {
  const userDoc = await getDoc(userRef(phoneNumber));
  return userDoc.exists();
};

const logOutUser = async () => {
  await signOut(auth);
};

const loadUserFromSession = async () => {
  return new Promise<{ uid: string; phoneNumber: string; userDB: UserModel | null }>((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const phoneNumber = user.phoneNumber!;
        const userDoc = await getDoc(userRef(phoneNumber));
        if (userDoc.exists()) {
          const userData = UserModel.fromFirestore(userDoc);
          resolve({
            uid: user.uid,
            phoneNumber,
            userDB: userData,
          });
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  });
};

export const AuthService = {
  sendSmsCode,
  verifyOtpCode,
  createUserInFirestore,
  checkUserExistsInFirestore,
  logOutUser,
  loadUserFromSession,
};
