// src/components/Forms/OTPForm.tsx
import React, { useState, useEffect, useRef } from "react";
import "./OTPForm.scss";
import FormsLoader from "./FormsLoader";
import { useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";

interface OTPFormProps {
  phoneNumber: string;
  onResend: () => void;
  onSubmit: (otp: string) => Promise<void>;
  onChangeNumber: () => void;
}

const OTPForm: React.FC<OTPFormProps> = ({ phoneNumber, onResend, onSubmit, onChangeNumber }) => {
  const [otpCode, setOtpCode] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { isLoading } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    if (timer > 0) {
      intervalRef.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [timer]);

  useEffect(() => {
    setTimer(60);
    setResendEnabled(false);
  }, [phoneNumber]);

  const handleResend = (e: React.MouseEvent) => {
    e.preventDefault();
    onResend();
    setResendEnabled(false);
    setTimer(60);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(otpCode);
  };

  return (
    <form onSubmit={handleSubmit} className="otp-form">
      <p>
        {t("login.sentOTP1") + " "}
        <span className="phone-number" onClick={onChangeNumber}>
          {phoneNumber}
        </span>
        , <br /> {t("login.sentOTP2")}
      </p>
      <input
        id="otp-code"
        inputMode="numeric"
        maxLength={6}
        value={otpCode}
        onChange={(e) => setOtpCode(e.target.value)}
        placeholder={t("login.verifyOTPInput.placeholder")}
        required
      />
      {isLoading ? <FormsLoader /> : <button type="submit">{t("login.verifyOTP")}</button>}

      <p>
        {resendEnabled ? (
          <a onClick={handleResend}>{t("login.resend")}</a>
        ) : (
          <>
            {t("login.resendWait")} {timer} {t("seconds")}
          </>
        )}
      </p>
    </form>
  );
};

export default OTPForm;
