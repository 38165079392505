import React from "react";
import "./FormsLoader.scss";

const FormsLoader = () => {
  return (
    <div className="forms-loader-container">
      <div className="forms-loader">
        <div className="justify-content-center jimu-primary-loading"></div>
      </div>
    </div>
  );
};

export default FormsLoader;
