import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, userDB } = useAppSelector((state: RootState) => state.auth);

  if (!isAuthenticated || !userDB) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
