import React from "react";
import "./AdminPage.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminPage = () => {
  const { t } = useTranslation();
  return (
    <div className="admin-page">
      <NavLink to="companies">
        <button>{t("lawCompanies.label")}</button>
      </NavLink>
      <NavLink to="documents">
        <button>{t("sidebar.normativeLegalActs")}</button>
      </NavLink>
    </div>
  );
};

export default AdminPage;
