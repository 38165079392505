import React, { useEffect, useRef, useState } from "react";
import "./ChatPage.scss";
import { MessageModel } from "../../../models/MessageModel";
import AnimatedMessageComponent from "../../layout/Chat/AnimatedMessage";
import MessageComponent from "../../layout/Chat/Message";
import MessageInput from "../../layout/Chat/MessageInput";
import {
  initializeChat,
  sendMessage,
  stopMessageAnimation,
  deleteMessages,
  setError,
} from "../../../app/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import trashIcon from "./../../../assets/icons/trash_icon.svg";
import Modal from "../../layout/Modal/Modal";
import PageLoader from "../../layout/PageLoader/PageLoader";
import Popup from "../../layout/Popup/Popup";
import { useTranslation } from "react-i18next";

const ChatPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { messages, isTyping, animatingMessages, lastChatId, isLoading, error } = useAppSelector(
    (state: RootState) => state.chat
  );
  const { user } = useAppSelector((state: RootState) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (user?.phoneNumber) {
      dispatch(initializeChat(user.phoneNumber)).then(() => {
        scrollToBottom();
      });
    }
  }, [dispatch, user?.phoneNumber]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, animatingMessages]);

  useEffect(() => {
    return () => {
      dispatch(stopMessageAnimation(""));
    };
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setIsPopupVisible(true);
    }
  }, [error]);

  const handleNewMessage = async (messageContent: string) => {
    if (lastChatId && user?.phoneNumber) {
      const newMessage: MessageModel = new MessageModel(undefined, messageContent, new Date(), true);
      await dispatch(sendMessage({ chatId: lastChatId, phoneNumber: user.phoneNumber, message: newMessage }));
    }
  };

  const handleMessageFinish = (messageId: string) => {
    dispatch(stopMessageAnimation(messageId));
  };

  const handleMessageTap = (messageId: string) => {
    dispatch(stopMessageAnimation(messageId));
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);
    await handleDeleteMessages();
  };

  const handleDeleteMessages = async () => {
    if (lastChatId && user?.phoneNumber) {
      await dispatch(deleteMessages());
    }
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    dispatch(setError(null));
  };

  return (
    <>
      <div className="chat-page">
        <div className="chat-header-wrapper">
          <div className="chat-header">
            <div className="title">
              <h1>Zanger.AI</h1>
            </div>
            <div className="status">
              <span className="online-dot"></span> Online
            </div>
          </div>
          <button
            className="delete-button"
            onClick={() => setIsModalVisible(true)}
            title={t("modal.deleteMessages.title")}
          >
            <img src={trashIcon} alt={t("modal.deleteMessages.title")} />
          </button>
        </div>

        <div className="messages" id="messages">
          {isLoading ? (
            <PageLoader />
          ) : (
            messages.map((message) => {
              const isAnimating = animatingMessages === message.id;
              if (isAnimating) {
                return (
                  <AnimatedMessageComponent
                    key={message.id}
                    message={message}
                    onMessageTap={() => handleMessageTap(message.id)}
                    onMessageFinish={() => handleMessageFinish(message.id)}
                  />
                );
              }
              return <MessageComponent key={message.id} message={message} />;
            })
          )}
          <div ref={messagesEndRef} />
        </div>
        <MessageInput onSendMessage={handleNewMessage} isTyping={isTyping} isLoading={isLoading} />
        <Modal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onConfirm={handleModalConfirm}
          title={t("modal.deleteMessages.title")}
          message={t("modal.deleteMessages.message")}
        />
        <Popup
          isVisible={isPopupVisible}
          onClose={handleClosePopup}
          title={t("popup.error.limitReached.title")}
          message={t("popup.error.limitReached.message")}
        />
      </div>
    </>
  );
};

export default ChatPage;
