// src/components/Forms/PhoneNumberForm.tsx
import React from "react";
import "./PhoneNumberForm.scss";
import usePhoneNumberFormatter from "../../../utils/usePhoneNumberFormatter";
import { useAppSelector } from "../../../app/hooks";
import FormsLoader from "./FormsLoader";
import { useTranslation } from "react-i18next";

interface PhoneNumberFormProps {
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => Promise<void>;
}

const PhoneNumberForm: React.FC<PhoneNumberFormProps> = ({ phoneNumber, setPhoneNumber, onSubmit }) => {
  const { handlePhoneNumberChange } = usePhoneNumberFormatter(phoneNumber, setPhoneNumber);
  const { isLoading } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className="phone-number-form">
      <h2>{t("login.welcome")}</h2>
      <label htmlFor="phone-number">{t("login.subtitle")}</label>
      <input
        id="phone-number"
        type="tel"
        maxLength={15}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        required
      />
      {isLoading ? <FormsLoader /> : <button type="submit">{t("login.login")}</button>}
    </form>
  );
};

export default PhoneNumberForm;
