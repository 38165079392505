import { useState } from "react";
import "./RegisterForm.scss";
import { useAppSelector } from "../../../app/hooks";
import FormsLoader from "./FormsLoader";
import { useTranslation } from "react-i18next";

interface RegisterFormProps {
  onSubmit: (firstName: string, lastName: string) => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { isLoading } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(firstName, lastName);
  };

  return (
    <form onSubmit={handleSubmit} className="register-form">
      <h2>{t("register.title")}</h2>
      <input
        type="text"
        value={firstName}
        onChange={handleFirstName}
        placeholder={t("register.firstNamePlaceholder")}
        required
      />
      <input
        type="text"
        value={lastName}
        onChange={handleLastName}
        placeholder={t("register.lastNamePlaceholder")}
        required
      />

      {!isLoading ? (
        <button type="submit" disabled={isLoading}>
          {t("register.submitButton")}
        </button>
      ) : (
        <FormsLoader />
      )}
    </form>
  );
};

export default RegisterForm;
