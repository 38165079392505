import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import "./RegisterPage.scss";
import RegisterForm from "../../layout/Forms/RegisterForm";
import { createUser } from "../../../app/auth/authSlice";
import { useEffect } from "react";
import { RootState } from "../../../app/store";

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated, userDB } = useAppSelector((state: RootState) => state.auth);

  if (!isAuthenticated) return <Navigate to="/login" />;

  if (isAuthenticated && userDB) return <Navigate to="/chat" />;

  const handleRegister = async (firstName: string, lastName: string) => {
    try {
      await dispatch(createUser({ firstName, lastName }));
      navigate("/chat");
    } catch (error) {
      console.error("Error during registration:", error);
      navigate("/login");
    }
  };

  return (
    <div className="register-page">
      <RegisterForm onSubmit={handleRegister} />
    </div>
  );
};

export default RegisterPage;
