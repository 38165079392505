import React, { useState } from "react";
import googlePlayBadge from "../../../assets/images/google_play_badge.png";
import appStoreBadge from "../../../assets/images/app_store_badge.png";
import "./HomePage.scss";
import { NavLink } from "react-router-dom";
import Popup from "../../layout/Popup/Popup";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { t } = useTranslation();

  const handleBadgeClick = () => {
    setIsPopupVisible(true);
  };

  return (
    <div className="homepage">
      <div className="container">
        <div className="homepage__content">
          <h1 className="homepage__title">Zanger.AI</h1>
          <p className="homepage__subtitle">{t("chat.yourPersonalAssistant")}</p>
          <NavLink to="/chat">
            <button className="homepage__button">{t("chat.askQuestion")}</button>
          </NavLink>
          <p className="homepage__text">{t("chat.extendedFunctionality")}</p>
          <div className="homepage__badges">
            <img src={appStoreBadge} alt="Download on the App Store" onClick={handleBadgeClick} />
            <img src={googlePlayBadge} alt="Get it on Google Play" onClick={handleBadgeClick} />
          </div>
        </div>
      </div>
      <Popup
        isVisible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        title={t("popup.download.title")}
        message={t("popup.download.message")}
      />
    </div>
  );
};

export default HomePage;
