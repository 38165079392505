import React from "react";
import "./Modal.scss";
import { useTranslation } from "react-i18next";

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, onConfirm, title, message, children }) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>{title}</h2>
        <p>{message}</p>
        {children}
        <div className="modal-actions">
          <button onClick={onClose}>{t("modal.cancelButton")}</button>
          <button onClick={onConfirm}>{t("modal.confirmButton")}</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
