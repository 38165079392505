import { firestore } from "../configs/firebase-config";
import { MessageModel } from "../models/MessageModel";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { UserModel } from "../models/UserModel";

class ChatService {
  private firestore = firestore;
  private usersRef = collection(this.firestore, "users");
  private configsRef = collection(this.firestore, "configs");
  private userRef(phoneNumber: string) {
    return doc(this.usersRef, phoneNumber);
  }
  private userChatsRef(phoneNumber: string) {
    return collection(this.userRef(phoneNumber), "chats");
  }
  private userChatRef(chatId: string, phoneNumber: string) {
    return doc(this.userChatsRef(phoneNumber), chatId);
  }
  private userChatMessagesRef(chatId: string, phoneNumber: string) {
    return collection(this.userChatRef(chatId, phoneNumber), "messages");
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  async fetchUserConfig(userType: string): Promise<number> {
    const configDoc = await getDoc(doc(this.configsRef, userType));
    const data = configDoc.data();
    return data ? data.count_of_messages : 0;
  }

  async addMessage(chatId: string, phoneNumber: string, message: MessageModel): Promise<void> {
    const userDoc = await getDoc(this.userRef(phoneNumber));
    if (!userDoc.exists()) {
      throw new Error("User not found");
    }

    const user = UserModel.fromFirestore(userDoc);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (!this.isSameDay(user.lastMessageDate, today)) {
      user.messagesToday = 0;
      user.lastMessageDate = today;
    }

    const userType = user?.userType || "standard_user";
    const messageLimit = await this.fetchUserConfig(userType);

    if (user.messagesToday >= messageLimit) {
      throw new Error(`You have reached your daily limit of ${messageLimit} messages.`);
    }

    await addDoc(this.userChatMessagesRef(chatId, phoneNumber), message.toFirestore());
    user.messagesToday += 1;
    user.lastMessageDate = new Date();
    await updateDoc(this.userRef(phoneNumber), user.toFirestore());
    await this.saveLastChatId(chatId, phoneNumber);
    await updateDoc(this.userChatRef(chatId, phoneNumber), { last_message_at: serverTimestamp() });
  }
  async getUserDailyLimit(userType: string): Promise<number> {
    const configDocRef = doc(this.configsRef, userType);
    const configDoc = await getDoc(configDocRef);
    const configData = configDoc.data();

    if (!configData) {
      throw new Error("Config not found");
    }

    return configData.dailyMessageLimit;
  }

  async createNewChat(phoneNumber: string): Promise<string> {
    const newChatRef = await addDoc(this.userChatsRef(phoneNumber), this.newChatData());
    return newChatRef.id;
  }

  async fetchMessages(chatId: string, phoneNumber: string): Promise<MessageModel[]> {
    if (!chatId || !phoneNumber) {
      throw new Error("chatId and phoneNumber must be non-empty strings.");
    }

    const chatRef = await getDoc(this.userChatRef(chatId, phoneNumber));
    if (!chatRef.exists()) {
      chatId = await this.createNewChat(phoneNumber);
    }

    const messagesQuery = query(this.userChatMessagesRef(chatId, phoneNumber), orderBy("sent_at", "asc"));
    const querySnapshot = await getDocs(messagesQuery);
    return querySnapshot.docs.map((doc) => MessageModel.fromFirestore(doc));
  }

  async saveLastChatId(chatId: string, phoneNumber: string) {
    await updateDoc(this.userRef(phoneNumber), { last_chat_id: chatId });
  }

  async getLastChatId(phoneNumber: string): Promise<string> {
    try {
      const snapshot = await getDoc(this.userRef(phoneNumber));
      const data = snapshot.data() as { last_chat_id: string };
      let chatId = data.last_chat_id;
      if (!chatId) {
        chatId = await this.createNewChat(phoneNumber);
      } else {
        const chatRef = await getDoc(this.userChatRef(chatId, phoneNumber));
        if (!chatRef.exists()) {
          chatId = await this.createNewChat(phoneNumber);
        }
      }
      await this.saveLastChatId(chatId, phoneNumber);
      return chatId;
    } catch (e) {
      return await this.createNewChat(phoneNumber);
    }
  }

  private newChatData() {
    return {
      title: "Новый чат",
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    };
  }

  async updateChatTimestamp(chatId: string, phoneNumber: string) {
    await updateDoc(this.userChatRef(chatId, phoneNumber), {
      updated_at: serverTimestamp(),
    });
  }

  async deleteMessages(chatId: string, phoneNumber: string) {
    const messagesQuery = query(this.userChatMessagesRef(chatId, phoneNumber));
    const querySnapshot = await getDocs(messagesQuery);
    querySnapshot.docs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      await this.updateChatTimestamp(chatId, phoneNumber);
    });
  }
}

export const chatService = new ChatService();
