import React, { useEffect, useRef, useState } from "react";
import "./Header.scss";
import logo from "../../../assets/images/logo.png";
import userIcon from "../../../assets/icons/user_icon.png";
import { NavLink } from "react-router-dom";
import { RootState } from "../../../app/store";
import { logOut } from "../../../app/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LanguageSwitcher from "./LanguageSwitcher";
import premiumIcon from "../../../assets/icons/premium_icon.png";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useAppDispatch();
  const userDB = useAppSelector((state: RootState) => state.auth.userDB);
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleLogout = async () => {
    await dispatch(logOut());
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="header__content">
          <div className="header__logo">
            <NavLink to={"/"}>
              <img src={logo} alt="Zanger AI Logo" />
            </NavLink>
          </div>
          <div className="header__title">
            <NavLink to={"/"}>
              <h1>Zanger.AI</h1>
            </NavLink>
          </div>
          <div className="header__right">
            <LanguageSwitcher />

            <div className="user__dropdown">
              <button className="user__button" onClick={toggleDropdown}>
                <img
                  src={userDB?.userType === "premium_user" ? premiumIcon : userIcon}
                  className={userDB?.userType === "premium_user" ? "premium__icon" : ""}
                  alt="User Avatar"
                />
              </button>
              {dropdownVisible && (
                <div className="dropdown__menu" ref={dropdownRef}>
                  {isAuthenticated && userDB ? (
                    <div className={`user__info `}>
                      <p>
                        {userDB?.firstName} {userDB?.lastName}
                      </p>
                      {isAuthenticated && userDB && userDB.phoneNumber === "+77776665555" && (
                        <NavLink to={"/admin"}>
                          <button className="admin__button">{t("user.adminPanel")}</button>
                        </NavLink>
                      )}

                      <button onClick={handleLogout} className="logout__button">
                        {t("user.logout")}
                      </button>
                    </div>
                  ) : (
                    <NavLink to={"/login"}>
                      <button className="login__button"> {t("user.login")}</button>
                    </NavLink>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
