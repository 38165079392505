import React, { useCallback, useEffect, useRef, useState } from "react";
import "./LoginPage.scss";
import PhoneNumberForm from "../../layout/Forms/PhoneNumberForm";
import OTPForm from "../../layout/Forms/OTPForm";
import Modal from "../../layout/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../../configs/firebase-config";
import { sendOTP, verifyOTP, checkUserExists, setIsSentOTP, clearError } from "../../../app/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { t } from "i18next";

const LoginPage: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("+7 ");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<"resend" | "change">("resend");
  const captchaContainerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state: RootState) => state.auth);

  const handlePhoneNumberSubmit = async () => {
    try {
      await dispatch(sendOTP(phoneNumber.replaceAll(" ", ""))).then(async (res) => {
        if (res.meta.requestStatus === "rejected") {
        }
      });
    } catch (error) {
      console.error("Error during phone number submission:", error);
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    try {
      const verificationId = authState.verificationId;
      if (verificationId) {
        await dispatch(verifyOTP({ verificationId, code: otp })).then(async (res) => {
          if (res.meta.requestStatus === "rejected") {
            return;
          }

          if (res.meta.requestStatus === "fulfilled") {
            try {
              const userExists = await checkUserExists(phoneNumber.replaceAll(" ", ""));
              if (userExists) {
                navigate("/chat");
              } else {
                navigate("/register");
              }
            } catch (error) {
              console.error("Error during user existence check:", error);
            }
          }
        });
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
    }
  };

  const handleResendOtp = () => {
    setModalAction("resend");
    setIsModalVisible(true);
  };

  const handleChangeNumber = () => {
    setModalAction("change");
    setIsModalVisible(true);
  };

  const handleModalConfirm = async () => {
    if (modalAction === "resend") {
      await handlePhoneNumberSubmit();
    } else if (modalAction === "change") {
      setPhoneNumber("");
      dispatch(setIsSentOTP(false));
    }
    setIsModalVisible(false);
  };

  const registerContainerRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      captchaContainerRef.current = node;
      node.id = "recaptcha-container-wrap";
      node.innerHTML = `<div id="recaptcha-container"></div>`;
    }
  }, []);

  useEffect(() => {
    if (!window._recaptchaVerifier) {
      window._recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response: any) => {
          window._captchaWidgetId = response;
        },
        "expired-callback": () => {
          window._recaptchaVerifier?.clear();
        },
      });
    }

    return () => {
      if (window._recaptchaVerifier) {
        window._recaptchaVerifier?.clear();
        window._captchaWidgetId = undefined;
        // window._recaptchaVerifier = undefined;
      }
      dispatch(setIsSentOTP(false));
    };
  }, [dispatch]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (authState.error) {
      timer = setTimeout(() => {
        dispatch(clearError());
      }, 30000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [authState.error, dispatch]);

  return (
    <div className="login-page">
      <div className="container">
        <div className="login-page__content">
          {!authState.isSentOTP ? (
            <>
              <PhoneNumberForm
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                onSubmit={handlePhoneNumberSubmit}
              />
              {authState.error && <div className="error-message">{authState.error}</div>}
            </>
          ) : (
            <>
              <OTPForm
                onChangeNumber={handleChangeNumber}
                phoneNumber={phoneNumber}
                onResend={handleResendOtp}
                onSubmit={handleOtpSubmit}
              />
              {authState.error && <div className="error-message">{authState.error}</div>}
            </>
          )}
        </div>
      </div>

      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onConfirm={handleModalConfirm}
        title={modalAction === "resend" ? t("modal.resendCode.title") : t("modal.changePhoneNumber.title")}
        message={modalAction === "resend" ? t("modal.resendCode.message") : t("modal.changePhoneNumber.message")}
      />
      <div ref={registerContainerRef} />
    </div>
  );
};

export default LoginPage;
