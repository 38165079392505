import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { DocumentsSection, DocumentsService } from "../../services/DocumentsService";
import { DocumentModel } from "../../models/DocumentModel";

interface DocumentState {
  documentSections: DocumentsSection[];
  isLoading: boolean;
  error: string | null;
  loaded: boolean;
  uploadProgress: { [key: string]: number };
}

const initialState: DocumentState = {
  documentSections: [],
  isLoading: false,
  error: null,
  loaded: false,
  uploadProgress: {},
};

export const fetchDocuments = createAsyncThunk(
  "documents/fetchDocuments",
  async (country: string, { rejectWithValue }) => {
    try {
      const documentSections = await DocumentsService.fetchDocuments(country);
      return documentSections;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "documents/uploadDocument",
  async (
    {
      country,
      folder,
      file,
      onProgress,
    }: { country: string; folder: string; file: File; onProgress: (progress: number) => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await DocumentsService.createDocument(country, folder, file, onProgress);
      await dispatch(fetchDocuments(country));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "documents/deleteDocument",
  async ({ country, document }: { country: string; document: DocumentModel }, { rejectWithValue, dispatch }) => {
    try {
      await DocumentsService.deleteDocument(country, document);
      await dispatch(fetchDocuments(country));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const documentSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentsLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    addNewFolder: (state, action: PayloadAction<string>) => {
      state.documentSections.push({
        title: action.payload,
        documents: [],
      });
    },
    setUploadProgress: (state, action: PayloadAction<{ filename: string; progress: number }>) => {
      const { filename, progress } = action.payload;
      state.uploadProgress[filename] = progress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<DocumentsSection[] | undefined>) => {
        state.isLoading = false;
        if (action.payload) {
          state.documentSections = action.payload;
        }
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(uploadDocument.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadDocument.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setDocumentsLoaded, addNewFolder, setUploadProgress } = documentSlice.actions;

export default documentSlice.reducer;
