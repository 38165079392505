import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AuthService } from "../../services/AuthService";
import { UserModel } from "../../models/UserModel";
import { RootState } from "../store";

interface AuthState {
  isAuthenticated: boolean;
  isSentOTP: boolean;
  isLoading: boolean;
  error: string | null;
  verificationId: string | null;
  user: {
    uid: string | null;
    phoneNumber: string | null;
  } | null;
  userDB: UserModel | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isSentOTP: false,
  isLoading: false,
  error: null,
  verificationId: null,
  user: null,
  userDB: null,
};

export const sendOTP = createAsyncThunk("auth/sendOTP", async (phone: string, { rejectWithValue }) => {
  try {
    const confirmationResult = await AuthService.sendSmsCode(phone);
    return {
      verificationId: confirmationResult.verificationId,
    };
  } catch (error: any) {
    console.error("Error sending OTP:", error);
    return rejectWithValue("Failed to send OTP. Please check the phone number and try again.");
  }
});

export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async ({ verificationId, code }: { verificationId: string; code: string }, { rejectWithValue }) => {
    try {
      const result = await AuthService.verifyOtpCode(verificationId, code);
      return result;
    } catch (error: any) {
      console.error("Error verifying OTP:", error);
      return rejectWithValue("Invalid verification code. Please try again.");
    }
  }
);

export const createUser = createAsyncThunk(
  "auth/createUser",
  async ({ firstName, lastName }: { firstName: string; lastName: string }, { rejectWithValue, dispatch, getState }) => {
    try {
      const user = (getState() as RootState).auth.user;
      if (!user) {
        return rejectWithValue("User not found.");
      }
      const phoneNumber = user.phoneNumber!;
      const userExists = await AuthService.checkUserExistsInFirestore(phoneNumber);
      if (userExists) {
        return rejectWithValue("User already exists.");
      }
      const userDB = await AuthService.createUserInFirestore(firstName, lastName, phoneNumber);
      dispatch(setUser({ uid: user.uid!, phoneNumber, userDB }));
    } catch (error: any) {
      console.error("Error creating user:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const checkUserExists = async (phoneNumber: string) => {
  return await AuthService.checkUserExistsInFirestore(phoneNumber);
};

export const logOut = createAsyncThunk("auth/logOut", async () => {
  localStorage.removeItem("user");
  window.location.href = "/";
  await AuthService.logOutUser();
});

export const loadUserFromSession = createAsyncThunk("auth/loadUserFromSession", async (_, { dispatch }) => {
  try {
    const userData = await AuthService.loadUserFromSession();
    dispatch(setUser(userData));
  } catch (error) {}
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ uid: string; phoneNumber: string; userDB: UserModel | null }>) => {
      state.user = { uid: action.payload.uid, phoneNumber: action.payload.phoneNumber };
      state.userDB = action.payload.userDB;
      state.isAuthenticated = true;
    },
    setIsSentOTP: (state, action: PayloadAction<boolean>) => {
      state.isSentOTP = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOut.fulfilled, () => initialState)
      .addCase(sendOTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSentOTP = true;
        state.verificationId = action.payload.verificationId;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSentOTP = false;
        state.verificationId = null;
        state.isAuthenticated = true;
        state.user = { uid: action.payload.uid, phoneNumber: action.payload.phoneNumber };
        state.userDB = action.payload.userDB;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(loadUserFromSession.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadUserFromSession.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(loadUserFromSession.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setUser, setIsSentOTP, clearError } = authSlice.actions;

export default authSlice.reducer;
