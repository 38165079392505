import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { fetchDocuments, uploadDocument, deleteDocument, addNewFolder } from "../../../app/document/documentSlice";
import { DocumentModel } from "../../../models/DocumentModel";
import Select from "react-select";
import "./AdminDocumentsPage.scss";
import PageLoader from "../../layout/PageLoader/PageLoader";
import SearchComponent from "../../layout/Documents/SearchComponent";
import DocumentsUploadInput from "../DocumentUploadInput/DocumentUploadInput";
import Modal from "../../layout/Modal/Modal";

const AdminDocumentsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { documentSections, isLoading, error } = useAppSelector((state: RootState) => state.documents);
  const [country, setCountry] = useState<string>("РК");
  const [newFolderName, setNewFolderName] = useState<string>("");
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [pendingUploads, setPendingUploads] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<DocumentModel | null>(null);

  useEffect(() => {
    dispatch(fetchDocuments(country)).then(() => {
      setSelectedFolder(documentSections[0]?.title || "");
    });
  }, [dispatch, country]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && selectedFolder) {
      setPendingUploads([...pendingUploads, ...Array.from(files)]);
    }
  };

  const handleConfirmUpload = async () => {
    for (const file of pendingUploads) {
      await dispatch(
        uploadDocument({
          country,
          folder: selectedFolder,
          file,
          onProgress: (progress) => {
            setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
          },
        })
      );
    }
    setPendingUploads([]);
    setUploadProgress({});
  };

  const handleDeleteDocument = (document: DocumentModel) => {
    setDocumentToDelete(document);
    setModalVisible(true);
  };

  const confirmDeleteDocument = async () => {
    if (documentToDelete) {
      setModalVisible(false);
      await dispatch(deleteDocument({ country, document: documentToDelete }));
      setDocumentToDelete(null);
    }
  };

  const handleDeletePendingUpload = (index: number) => {
    const updatedUploads = pendingUploads.filter((_, i) => i !== index);
    setPendingUploads(updatedUploads);
    setUploadProgress((prev) => {
      const newProgress = { ...prev };
      delete newProgress[pendingUploads[index].name];
      return newProgress;
    });
  };

  const handleCreateFolder = async () => {
    if (newFolderName) {
      const folderExists = documentSections.some((section) => section.title === newFolderName);
      if (!folderExists) {
        dispatch(addNewFolder(newFolderName));
        setNewFolderName("");
      } else {
        console.error("Folder already exists");
      }
    }
  };

  const filteredDocumentSections = documentSections
    .map((section) => ({
      ...section,
      documents: section.documents.filter((document) =>
        document.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((section) => section.documents.length > 0);

  const folderOptions = documentSections.map((section) => ({
    value: section.title,
    label: section.title,
  }));

  const [showMore, setShowMore] = useState<{ [key: string]: boolean }>({});

  const toggleShowMore = (title: string) => {
    setShowMore((prevState) => ({ ...prevState, [title]: !prevState[title] }));
  };

  return (
    <div className="admin-documents-page">
      <h1>Управление документами</h1>
      <div className="upload-section">
        <input
          type="text"
          placeholder="Новый раздел"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
        />
        <button onClick={handleCreateFolder}>Создать раздел</button>
      </div>
      <div className="upload-section">
        <Select
          value={folderOptions.find((option) => option.value === selectedFolder)}
          options={folderOptions}
          onChange={(option) => setSelectedFolder(option?.value || "")}
          placeholder="Выберите раздел"
          isClearable
        />
        <DocumentsUploadInput onChange={handleFileUpload} label="Загрузить документы" accept=".pdf" />
        {pendingUploads.length > 0 && (
          <div className="pending-uploads">
            <h3>Pending Uploads</h3>
            <ul className="pending-upload-list">
              {pendingUploads.map((file, index) => (
                <li key={index}>
                  {file.name} - {uploadProgress[file.name]?.toFixed(2)}%
                  <button onClick={() => handleDeletePendingUpload(index)} disabled={isLoading}>
                    Удалить
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={handleConfirmUpload} disabled={isLoading}>
              Подтвердить загрузку
            </button>
          </div>
        )}
      </div>
      <SearchComponent searchValue={searchQuery} onSearchChange={(e) => setSearchQuery(e.target.value)} />
      <div className="document-sections">
        {isLoading ? (
          <PageLoader />
        ) : (
          filteredDocumentSections.map((section) => (
            <div key={section.title} className="document-section">
              <h2>{section.title}</h2>
              <ul className="document-list">
                {section.documents.slice(0, showMore[section.title] ? section.documents.length : 5).map((document) => (
                  <li key={document.name}>
                    <a href={document.url} target="_blank" rel="noopener noreferrer">
                      {document.name}
                    </a>
                    <button onClick={() => handleDeleteDocument(document)}>Удалить</button>
                  </li>
                ))}
              </ul>
              {section.documents.length > 5 && (
                <button onClick={() => toggleShowMore(section.title)}>
                  {showMore[section.title] ? "Скрыть" : "Раскрыть"}
                </button>
              )}
            </div>
          ))
        )}
      </div>
      <Modal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={confirmDeleteDocument}
        title="Подтверждение удаления"
        message="Вы уверены, что хотите удалить этот документ? Это действие необратимо."
      />
    </div>
  );
};

export default AdminDocumentsPage;
