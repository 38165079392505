import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import "./LawCompaniesPage.scss";
import { fetchLawCompanies } from "../../../app/lawCompany/lawCompanySlice";
import PageLoader from "../../layout/PageLoader/PageLoader";
import { useTranslation } from "react-i18next";

const LawCompaniesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { lawCompanies, isLoading } = useAppSelector((state: RootState) => state.lawCompanies);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchLawCompanies()).then(() => {});
  }, [dispatch]);

  return (
    <div className="law-companies-page">
      <h2>{t("lawCompanies.label")}</h2>
      {isLoading && <PageLoader />}
      <ul className="law-companies-list">
        {lawCompanies.map((company, index) => (
          <li key={company.id} className="law-company-card">
            <h3>
              {index + 1}. {company.name}
            </h3>
            <p>{company.description}</p>
            {t("lawCompanies.address")}:{" "}
            <a href={company.link2gis} target="_blank" rel="noopener noreferrer">
              {company.address}
            </a>
            <br />
            {t("lawCompanies.phoneNumber")}: <a href={`tel:${company.phoneNumber}`}>{company.phoneNumber}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LawCompaniesPage;
