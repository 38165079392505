import React from "react";
import "./Popup.scss";

interface PopupProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

const Popup: React.FC<PopupProps> = ({ isVisible, onClose, title, message }) => {
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="popup-actions">
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
