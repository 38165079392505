import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LawCompaniesService } from "../../services/LawCompaniesService";
import { LawCompanyModel } from "../../models/LawCompanyModel";

interface LawCompanyState {
  lawCompanies: LawCompanyModel[];
  isLoading: boolean;
  error: string | null;
}

const initialState: LawCompanyState = {
  lawCompanies: [],
  isLoading: false,
  error: null,
};

export const fetchLawCompanies = createAsyncThunk("lawCompanies/fetchLawCompanies", async (_, { rejectWithValue }) => {
  try {
    const service = new LawCompaniesService();
    const lawCompanies = await service.getLawCompanies();
    return lawCompanies;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const addLawCompany = createAsyncThunk(
  "lawCompanies/addLawCompany",
  async (company: LawCompanyModel, { rejectWithValue, dispatch }) => {
    try {
      const service = new LawCompaniesService();
      await service.addCompany(company);
      await dispatch(fetchLawCompanies());
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLawCompany = createAsyncThunk(
  "lawCompanies/updateLawCompany",
  async (company: LawCompanyModel, { rejectWithValue, dispatch }) => {
    try {
      const service = new LawCompaniesService();
      await service.updateCompany(company);
      await dispatch(fetchLawCompanies());
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLawCompany = createAsyncThunk(
  "lawCompanies/deleteLawCompany",
  async (company: LawCompanyModel, { rejectWithValue, dispatch }) => {
    try {
      const service = new LawCompaniesService();
      await service.deleteCompany(company);
      await dispatch(fetchLawCompanies());
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const lawCompanySlice = createSlice({
  name: "lawCompanies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLawCompanies.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLawCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lawCompanies = action.payload;
      })
      .addCase(fetchLawCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(addLawCompany.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addLawCompany.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addLawCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateLawCompany.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLawCompany.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateLawCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteLawCompany.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteLawCompany.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteLawCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default lawCompanySlice.reducer;
