import React from "react";
import "./Footer.scss";
import LanguageSwitcher from "../Header/LanguageSwitcher";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <footer className="footer">
        <h4>Zanger.AI | 2024</h4>
        <br />
        <p>
          {t("support")}: <a href="tel:+7 (777) 989-28-88">+7 (777) 989-28-88</a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
