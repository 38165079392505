import { FirebaseOptions, initializeApp } from "firebase/app";
import { browserSessionPersistence, getAuth, setPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { enableMapSet } from "immer";

enableMapSet();

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = true;

setPersistence(auth, browserSessionPersistence)
  .then(() => {})
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("Error setting persistence:", errorCode, errorMessage);
    console.error("Error setting persistence:", error);
  });

export { auth, firestore, storage };
